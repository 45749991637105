<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
          <p class="page-text__text">Mówiła: "Zróbcie wszystko, co Bóg wam powie."</p>
          <p class="page-text__text">
            Zwana Świętą Marią z Nazaretu była żoną św. Józefa, urodziła się ok 20 r. p.n.e. w Jerozolimie lub w miasteczku
            oddalonym o 6 km od Nazaretu - Serofis. Jej rodzicami byli Joachim oraz Anna, którzy długo nie mogli mieć
            dziecka. Postanowili, że jeżeli doczekają się potomstwa oddadzą je na służbę Bogu. Po narodzinach Marii oddali ją
            do świątyni gdzie przebywała do 14. roku życia. Po tym czasie nakazano jej wrócić do domu, aby mogła wejść w
            związek małżeński. Mężczyźni nieżonaci z rodu Dawida, kandydaci na męża Marii, złożyli gałązki na ołtarzu. Na
            jednej z nich miał zasiąść Duch Święty pod postacią gołębicy. Stało się to dopiero wtedy, gdy Józef ofiarował
            swoją gałązkę. Anioł oznajmił Marii w Nazarecie: „Oto poczniesz i porodzisz Syna, któremu nadasz imię Jezus. W
            łonie Marii poczęło się z Ducha Świętego niezwykłe dziecko.
          </p>
          <p class="page-text__text">
            Matka Boża towarzyszyła swojemu synowi do ostatnich chwil Jego życia. Maria z Nazaretu to osoba pełna pokory,
            która sama siebie określała jako „służebnicę Pańską”. Swoim życiem uczy Nas oddania, wierności, cichości,
            posłuszeństwa, pokory, w centrum stawiała Boga, nie siebie.
          </p>
          <p class="page-text__text">
            Święta Maria odznacza się wieloma tytułami. Nazywana jest Orędowniczką, Królową Apostołów, Matką Kościoła. Na
            całym świecie znajdują się miejsca Maryjne takie jak Fatima, Lourdes, Licheń, Częstochowa a także gostyńska
            Święta Góra.
          </p>
          <p class="page-text__text">
            Kult Matki Bożej na Świętej Górze istniał już w średniowieczu a samo określenie "Święta Góra" pochodzi być może
            jeszcze z czasów prehistorycznych. Jedna z hipotez mówi, że to misjonarze, którzy przybyli na ten teren zamienili
            ośrodek pogańskiego kultu na "miejsce Matki Bożej". Prawdopodobnie byli to benedyktyni z pobliskiego Lubinia.
          </p>
          <p class="page-text__text">
            Właściciele Gostynia, jednej z najstarszych osad Wielkopolski, czuwali nad Świętą Górą. Sława tego miejsca rosła
            wciąż, gdyż pomiędzy ludźmi rozchodziła się wieść o Cudownym Wizerunku Matki Bożej. Wierni, którzy tu przybywali
            wypraszali wiele łask dla siebie i swoich bliskich.
          </p>
          <p class="page-text__text">
            Do dziś sercem i duszą świętogórskiego sanktuarium jest królująca w głównym ołtarzu Matka Boża z Dzieciątkiem i
            kwiatem róży w dłoni. Wierni nazywają Ją Świętogórską Różą Duchowną, Królową Wielkopolski.
          </p>
          <div class="btn" style="display: flex">
            <router-link to="/patroni">
              <Btn text="Powrót" :reverse="true" />
            </router-link>
          </div>
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
};
</script>
<style lang="scss" scoped>
</style>
